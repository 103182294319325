<template>
  <div class="import-warranty-stocks">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview v-bind:title="'Danh sách phiếu nhập kho bảo hành'">
      <template v-slot:toolbar>
        <div class="row" v-if="checkPermission('STOCK_INSERT')">
          <b-dropdown size="sm" id="dropdown-1" right class="mr-2">
            <template slot="button-content">
              <i style="font-size: 1rem" class="fas fa-cog"></i>
              <span class="font-weight-bolder">Thao tác</span>
            </template>
            <b-dropdown-item @click="reportClick">
              <span>
                <i style="font-size: 1rem" class="far fa-file-excel"></i>
                &nbsp; Xuất Excel</span
              >
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown
            size="sm"
            variant="warning"
            right
            :disabled="countSelected ? false : true"
          >
            <template slot="button-content">
              <span class="font-weight-bolder">
                <span
                  class="count-status badge badge-circle badge-white mr-1"
                  >{{ countSelected }}</span
                >
                Đã chọn
              </span>
            </template>
            <b-dropdown-item @click="showCreateTransferAlert">
              <i class="fa-solid fa-arrows-repeat-1"></i> Tạo phiếu chuyển
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <b-row class="mb-5">
          <b-col>
            <div class="d-flex justify-content-center align-items-center">
              <date-picker
                placeholder="Từ ngày"
                class="form-control-sm"
                :config="dpConfigs.start"
                v-model="dpForm.startDate"
              ></date-picker>
            <span class="ml-1 mr-1"></span>
            <date-picker
                placeholder="Đến ngày"
                class="form-control-sm"
                :config="dpConfigs.end"
                v-model="dpForm.endDate"
              ></date-picker>
            </div>
          </b-col>
          <b-col>
            <BDropdownCustom
              v-model="valueStore"
              :options="filterdOptionsStore"
              :placeholder="'cửa hàng'"
              :matchKey="['name']"
              :searchable="true"
              :limit="5"
            />
          </b-col>
          <b-col>
            <Autosuggest
              :model="searchProduct"
              :suggestions="filteredOptionsProduct"
              placeholder="mã, tên, mã vạch sản phẩm"
              :limit="10"
              @select="onSelectedProduct"
              @change="onInputChangeProduct"
              suggestionName="productName"
            />
          </b-col>
          <b-col>
            <Autosuggest
              :model="searchCustomer"
              :suggestions="filteredOptionsCustomer"
              placeholder="SĐT Khách hàng"
              :limit="10"
              @select="onSelectedCustomer"
              @change="onInputChangCustomer"
              suggestionName="phoneNo"
            >
              <template #custom="{suggestion}">
                <div>
                  <div class="d-flex flex-column">
                    <span class="text-primary mb-1" v-if="suggestion.item.phoneNo"
                      >({{ suggestion.item.phoneNo }})</span
                    >
                    <span class="font-weight-bold" v-if="suggestion.item.fullName">{{
                      suggestion.item.fullName
                    }}</span>
                  </div>
                </div>
              </template>
            </Autosuggest>
          </b-col>
          <b-col>
            <b-form-select
              class="select-style"
              size="sm"
              v-model="selectedCompany"
              :options="listCompany"
              required
              value-field="id"
              text-field="name"
              v-on:change="onChangeCompany"
            >
              <template v-slot:first>
                <b-form-select-option :value="null" disabled
                  >-- Doanh nghiệp --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-form-input
              v-model="searchId"
              type="text"
              placeholder="Mã phiếu"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col>
            <b-form-input
              v-model="searchBillId"
              type="text"
              placeholder="Mã hóa đơn"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          
          <b-col>
            <b-form-input
              v-model="searchDesciption"
              type="text"
              placeholder="Tìm kiếm theo nội dung"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col></b-col>
          <b-col></b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="items"
          :fields="fields"
          :busy="onLoading"
          bordered
          hover
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>
          <template v-slot:head(selected)>
            <span>
              <b-form-checkbox
                v-model="checkAllItem"
                size="lg"
                @change="checkAll"
              ></b-form-checkbox>
            </span>
          </template>
          <!-- check bõ -->
          <template v-slot:cell(selected)="row">
            <div class="d-flex justify-content-center">
              <b-form-checkbox
                size="lg"
                v-model="row.item.selected"
                @change="clickIndex"
              ></b-form-checkbox>
            </div>
          </template>

          <template v-slot:cell(code)="row">
            <div class="productCode" style="cursor: pointer">
              <a
                class="font-weight-bold"
                style="color: black"
                v-bind:href="getLinkDoc(row.item)"
                target="_blank"
              >
                {{ row.item.code }}
              </a>
            </div>
          </template>
          <template v-slot:cell(billId)="row">
            <div class="productCode" style="cursor: pointer">
              <a
                class="font-weight-bold"
                style="color: #3699ff"
                v-bind:href="getLinkBill(row.item.billId)"
                target="_blank"
              >
                {{ row.item.billId }}
              </a>
            </div>
          </template>

          <template v-slot:cell(customerPhone)="row">
            <div>
              <p
                class="productCode"
                @click="editUser(row.item)"
                style="margin-bottom: 0; cursor: pointer; font-weight: 550"
              >
                {{ row.item.customerName }}
              </p>
              <p
                class="productCode"
                @click="editUser(row.item)"
                style="margin-bottom: 0; cursor: pointer"
              >
                {{ row.item.customerPhone }}
              </p>
            </div>
          </template>

          <template v-slot:cell(importDate)="row">
            <div style="text-align: left">
              <span
                v-text="row.item.importDate"
                class="text-left text-primary mb-0"
              ></span>
              <p class="text-left">{{ row.item.createBy }}</p>
            </div>
          </template>
          <template v-slot:cell(sourceWarehouseId)="row">
            <span
              v-text="row.item.sourceWarehouseId"
              class="font-weight-bolder"
            ></span>
            <br />
            <span v-if="row.item.companyName"
              >( {{ row.item.companyName }} )</span
            >
          </template>
          <template v-slot:cell(description)="row">
            <div
              @mouseover="hoverEditNote = true"
              @mouseleave="hoverEditNote = false"
            >
              <span v-text="row.item.description" class="mr-2"></span>
              <span @click="handleShowNoteModel(row.item)">
                <i v-if="hoverEditNote" class="fas fa-edit"></i>
              </span>
            </div>
          </template>

          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item @click="printItem(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-printer"></i>
                    &nbsp; In phiếu
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="editItem(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item 
                  @click="exportItem(row.item)"
                  v-if="checkPermission('STOCK_INSERT')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="fas fa-file-export"></i>
                    &nbsp; Xuất trả bảo hành
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(row.item)"
                  v-if="checkPermission('STOCK_DELETE')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng:
              {{ convertPrice(totalItems) }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              v-model="page"
              class="customPagination"
              v-show="totalPages >= 2"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchData"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm m-1"
              prev-class="page-item-prev btn btn-icon btn-sm m-1"
              next-class="page-item-next btn btn-icon btn-sm m-1 "
              last-class="page-item-last btn btn-icon btn-sm m-1 "
              page-class="btn btn-icon btn-sm border-0 m-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
        <template>
          <b-modal
            v-model="showEditNoteModal"
            hide-footer
            :title="'Sửa ghi chú cho phiếu: ' + stockSlipCode"
          >
            <b-row class="mb-5">
              <b-col>
                <label class="labelInput" for="inputOriginalPrice"
                  >Ghi chú</label
                >
                <b-form-textarea
                  v-model="description"
                  placeholder="Nhập ghi chú..."
                  rows="5"
                  max-rows="6"
                ></b-form-textarea>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="updateNote"
                  >Lưu</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="handleShowNoteModel"
                  >Hủy</b-button
                >
              </b-col>
            </b-row>
          </b-modal>
        </template>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import { BASE_URL, TIME_TRIGGER } from '../../../utils/constants';
import { VclTable } from 'vue-content-loading';
import Swal from 'sweetalert2';
import decounce from 'debounce';
import localData from '@/utils/saveDataToLocal';
import axios from 'axios';
import fileDownload from '@/utils/file-download';
import { removeAccents, convertPrice } from '@/utils/common';
import { cloneDeep, map, find, assign } from 'lodash';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { STOCK_SLIP_TYPE } from '../../../utils/enum';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';

export default {
  data() {
    return {
      dpForm: {
        startDate: '',
        endDate: '',
      },
      dpConfigs: {
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      onLoading: false,
      btnCreate: {
        fontWeight: '600!important',
      },
      items: [],
      searchDesciption: '',
      searchStock: '',
      searchId: '',
      currentPage: 0,
      totalPages: 1,
      totalItems: 0,
      page: 1,
      searchFromDay: '',
      searchToDay: '',
      fields: [
        {
          key: 'selected',
          label: '',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '4%',
          },
          tdClass: 'sttClass',
        },
        {
          key: 'code',
          label: 'Mã phiếu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'billId',
          label: 'Từ hóa đơn',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'customerPhone',
          label: 'Khách hàng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'sourceWarehouseId',
          label: 'Kho',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'description',
          label: 'Nội dung',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '25%' },
        },
        {
          key: 'importDate',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        { key: 'actions', label: '', thStyle: { width: '5%' } },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      limit: 10,
      searchProduct: '',
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      optionsStore: [
        {
          data: [],
        },
      ],
      selectedCompany: null,
      listCompany: [],
      valueStore: [],
      filteredOptionsCustomer: [],
      optionsCustomer: [
        {
          data: [],
        },
      ],
      searchCustomer: '',
      selectedCustomer: null,
      hoverEditNote: false,
      stockSlipCode: '',
      showEditNoteModal: false,
      description: '',
      searchBillId: '',
      selectedId: [],
      checkAllItem: false,
      filterdOptionsStore:[]
    };
  },
  computed: {
    countSelected() {
      const selectedItem = this.items.filter((item) => item.selected);
      return selectedItem.length;
    },
  },
  methods: {
    convertPrice,
    fetchStore: async function () {
      this.optionsStore = [];
      ApiService.get(
        `/stores/getStores?companyId=${this.selectedCompany}`
      ).then((response) => {
        const stores = response.data.data;
        this.optionsStore = stores.map((element) => {
          return {
            code: element.id,
            name: element.name,
            shortName: element.shortName,
            checked: false,
          };
        });
        this.filterdOptionsStore = cloneDeep(this.optionsStore);
      });
    },
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          this.optionsStore = [];
          const stores = data.data.stores;
          stores.forEach((element) => {
            const option = {
              code: element.id,
              name: element.name,
              shortName: element.shortName,
              checked: false,
            };
            this.optionsStore.push(option);
            if (!data.data.viewAll) {
              let index = this.optionsStore.findIndex(
                (item) => item.code == element.id
              );
              this.optionsStore[index].checked = true;
              this.valueStore.push(this.optionsStore[index]);
            }
          });
          this.filterdOptionsStore = cloneDeep(this.optionsStore);
        }
        this.fetchData();
      });
    },
    fetchData: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      let listStoreId = [];
      if (this.valueStore.length) {
        listStoreId = this.valueStore.map(({ code }) => code);
      }
      if (this.searchCustomer.trim() === '') {
        this.selectedCustomer = null;
      }

      const params = {
        page: this.page,
        pageSize: 10,
        searchDesciption: this.searchDesciption.trim(),
        searchCode: this.searchId.trim(),
        searchFromDay: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        searchToDay: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        type: [STOCK_SLIP_TYPE.IMPORT_WARRANTY, STOCK_SLIP_TYPE.IMPORT_WARRANTY_FROM_BILL],
        searchProduct: this.searchProduct,
        listStoreId: listStoreId,
        selectedCustomer: this.selectedCustomer,
        billId: this.searchBillId.trim(),
      };

      ApiService.query('warranty-stock', { params })
        .then((response) => {
          const stockResponse = response.data.data.result;
          this.totalPages = response.data.data.totalPages;
          this.currentPage = response.data.data.currentPage;
          this.totalItems = response.data.data.totalItems;
          this.items = stockResponse.map((item, index) => {
            return {
              count:
                this.currentPage >= 2
                  ? index + 1 + this.currentPage * 10 - 10
                  : index + 1,
              id: item.id,
              code: item.code,
              sourceWarehouseId: item.sourceWarehouseId,
              providerId: item.providerId,
              description: item.description,
              totalAmount: item.totalAmount,
              type: item.type,
              importDate: moment(String(item.importDate)).format('DD/MM/YYYY'),
              createBy: item.createBy,
              updateBy: item.updateBy,
              customerName: item.customerName,
              customerPhone: item.customerPhone,
              billId: item.billId,
              selected: false,
            };
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = true;
        });
    },
    editItem: function (item) {
      this.$router.push({
        name: 'update-import-warranty',
        query: { id: item.id },
      });
    },
    exportItem: function (item) {
      this.$router.push({
        name: 'add-export-warranty',
        query: { id: item.id },
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa phiếu nhập kho bảo hành!',
        text: 'Bạn có chắc muốn xóa phiếu nhập kho bảo hành này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    deleteItem: async function (item) {
      ApiService.delete(`stock-slips/${item.id}`).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.fetchData();
        } else {
          this.makeToastFaile(data.message);
        }
      });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    fetchProduct() {
      this.optionsProduct[0].data = [];
      ApiService.get(
        `productSearch?searchProduct=${this.searchProduct}&storeId=&includeParent=true`
      ).then(({ data }) => {
        const products = data.data;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [...this.optionsProduct[0].data];
      });
    },
    debounceInput: decounce(function () {
      this.fetchProduct();
    }, TIME_TRIGGER),
    onSelectedProduct(option) {
      this.searchProduct = option.item.productName;
    },
    onInputChangeProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInput();
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-import-warranty',
      });
      this.fetchData();
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    onSelectStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name
      );
      this.optionsStore[index].checked = true;
      this.filterdOptionsStore = cloneDeep(this.optionsStore);
    },
    onRemoveStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name
      );
      this.optionsStore[index].checked = false;
      this.filterdOptionsStore = cloneDeep(this.optionsStore);
    },
    onInputStoreChange(textInput = '') {
        this.searchStore(textInput);
    },
    searchStore(textInput) {
     let options = cloneDeep(this.optionsStore);
      if (!textInput || !textInput.trim().length) {
        this.filterdOptionsStore = map(options, obj => {
          return assign(obj, find(this.filterdOptionsStore, { id: obj.id }));
        });
        return;
      }

      const indexChooseAll = options.findIndex(prop => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, obj => {
        return assign(obj, find(this.filterdOptionsStore, { id: obj.id }));
      });

      this.filterdOptionsStore = this.fitlerOptionsBy(options, textInput, 'name', 10);
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter(item => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
    fetchCompany: async function () {
      this.listCompany = [];
      ApiService.get('/company').then((response) => {
        this.listCompany = response.data.data;
        this.listCompany.unshift({
          id: null,
          name: 'Tất cả',
        });
      });
    },
    onChangeCompany() {
      this.valueStore = [];
      this.searchStock = '';
      this.fetchStore();
    },
    getLinkDoc(item) {
      return `#/import-warranty-stocks/update-import-warranty?id=${item.id}`;
    },
    onSelectedCustomer(option) {
      this.searchCustomer = option.item.phoneNo;
      this.selectedCustomer = option.item.id;
    },
    onInputChangCustomer(text) {
      if (!text) {
        text = '';
      }
      this.searchCustomer = text;
      this.optionsCustomer[0].data = [];
      this.filteredOptionsCustomer = [...this.optionsCustomer[0].data];
      this.debounceInputCustomer();
    },
    debounceInputCustomer: decounce(function () {
      this.getListCustomer();
    }, TIME_TRIGGER),
    getListCustomer() {
      const text = this.searchCustomer;
      ApiService.get(`customer/get-by-phone?phoneNumber=${text}`).then(
        (data) => {
          const customers = data.data.data;
          this.listCustomer = customers;
          customers.map((element) => {
            let cus = {
              id: element.id,
              phoneNo: element.phoneNo,
              fullName: element.fullName,
            };
            this.optionsCustomer[0].data.push(cus);
          });
          this.filteredOptionsCustomer = [...this.optionsCustomer[0].data];
        }
      );
    },
    handleShowNoteModel(item) {
      this.showEditNoteModal = !this.showEditNoteModal;
      this.stockSlipCode = item.code;
      this.description = item.description;
    },
    updateNote() {
      const data = {
        stockSlipCode: this.stockSlipCode,
        description: this.description,
      };
      ApiService.post('stocks/update-stockslip-note', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.showEditNoteModal = !this.showEditNoteModal;
            this.fetchData();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    getLinkBill(billNumber) {
      return `#/bills/update-bill?id=${billNumber}`;
    },
    printItem: function (item) {
      this.$router.push({
        name: 'print-export-stockslip',
        query: { id: item.id },
      });
    },
    checkAll() {
      this.items.forEach((element) => {
        element.selected = this.checkAllItem;
      });
    },
    clickIndex() {
      if (this.checkAllItem == true) {
        this.checkAllItem = false;
      } else {
        let count = 0;
        this.items.forEach((element) => {
          if (element.selected == true) {
            count += 1;
            if (count == 10) {
              this.checkAllItem = true;
            }
          }
        });
      }
    },
    showCreateTransferAlert: function () {
      Swal.fire({
        title: 'Tạo phiếu chuyển kho!',
        text: 'Bạn có chắc muốn tạo phiếu chuyển kho từ các phiếu nhập kho bảo hành đã chọn không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Tạo',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.createTransfer();
        }
      });
    },
    createTransfer() {
      const selectedId = this.items
        .filter((item) => item.selected)
        .map((item) => item.id);
      this.$router.push({
        name: 'add-transfer-stock',
        query: {
          warrantyIds: selectedId,
        },
      });
    },
    reportClick: async function () {
      let listStoreId = [];
      if (this.valueStore.length) {
        this.valueStore.forEach((element) => {
          listStoreId.push(element.code);
        });
      };
      if (this.searchCustomer.trim() === '') {
        this.selectedCustomer = null;
      }

      const params = {
        searchDesciption: this.searchDesciption.trim(),
        searchCode: this.searchId.trim(),
        searchFromDay: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 00:00:00'
            )
          : '',
        searchToDay: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 23:59:59'
            )
          : '',
        type: 15,
        searchProduct: this.searchProduct,
        listStoreId: listStoreId,
        selectedCustomer: this.selectedCustomer,
        billId: this.searchBillId.trim(),
      };

      const url = `${BASE_URL}warranty-stock/export-excel`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
        params: params,
      }).then((response) => {
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Nhập kho bảo hành', route: 'import-warranty-stocks' },
      { title: 'Danh sách phiếu nhập kho bảo hành' },
    ]);
  },
  created() {
    let code = this.$route.query.code;
    if (code !== undefined) {
      this.searchId = code;
    }
    this.fetchStore();
    this.fetchCompany();
    this.fetchStoreByUser();
  },
  components: {
    KTCodePreview,
    VclTable,
    Autosuggest,
    BDropdownCustom,
  },
};
</script>

<style lang="scss">
.import-warranty-stocks {
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }

  code,
  pre {
    margin: 1em 0;
    padding: 1em;
    border: 1px solid #bbb;
    display: block;
    background: #ddd;
    border-radius: 3px;
  }

  .settings {
    margin: 2em 0;
    border-top: 1px solid #bbb;
    background: #eee;
  }

  .form-group {
    margin-bottom: 1em;
  }

  .btn.btn-icon.btn-sm,
  .btn-group-sm > .btn.btn-icon {
    height: calc(1.35em + 1.1rem + 2px);
    width: auto;
  }

  .icon:hover {
    background-color: #90c6fc;
  }

  .sttClass {
    width: 20px;
  }

  ::-webkit-datetime-edit-year-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-month-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
    color: transparent;
  }

  .productCode:hover {
    text-decoration: underline;
  }

  .checkbox-label {
    display: block;
  }
  .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }
}
</style>
